<template>
  <div
    v-if="message"
    class="mm-warning-message"
  >
    <SvgIcon
      class="mm-warning-message__icon"
      :src="EIconPath.NavigationInfo"
    />
    <span
      class="mm-warning-message__text"
      v-html="sanitizedMessage"
    />
  </div>
</template>

<script lang="ts" setup>
import { EIconPath } from 'shared/enums/iconPath.enum';
import { sanitize } from 'shared/directives/sanitize';
import { ShowdownService } from 'services/showdown.service';

const props = withDefaults(
  defineProps<{
    variant: 'client' | 'supplier';
  }>(),
  {
    variant: 'client',
  },
);

const runtimeConfig = useRuntimeConfig();

const message = computed(
  () => props.variant === 'client'
    ? runtimeConfig.public.clientWarning
    : props.variant === 'supplier'
      ? runtimeConfig.public.supplierWarning
      : '',
);

const sanitizedMessage = computed(() => sanitize(ShowdownService.getText(message.value)));
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-warning-message {
  padding: 16px 40px;
  background-color: rgba(241, 53, 53, 0.1);
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  &__icon {
    margin-right: 12px;
    min-width: 20px;
    min-height: 20px;
  }

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $text-black;

    :deep(a) {
      color: $link;
      text-decoration: none;
      transition: color 0.2s;
      cursor: pointer;

      &:hover {
        color: $text-dark-green;
      }
    }
  }
}

@media only screen and (max-width: 1599px) {
  .mm-warning-message {
    padding: 16px 32px;
  }
}

@media only screen and (max-width: 1279px) {
  .mm-warning-message {
    padding: 16px 20px;
  }

  :global(.mm-header) {
    margin-top: 14px;
  }
}
</style>
